import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { AboutLayout, Desi, Div } from '../components/desi-bio.js';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = AboutLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "about-desi-maxwell"
    }}>{`About Desi Maxwell`}</h2>
    <Div sx={{
      maxWidth: '300px',
      marginBottom: 3
    }} mdxType="Div">
    <Desi mdxType="Desi" />
    </Div>
    <p>{`Desi grew up in County Antrim, N. Ireland. After studies at the University of Ulster, then Westminster and Princeton theological Seminaries, he served for seven years in pastoral ministry. For the next twenty years he lectured at Belfast Bible College, before seeking to bring the classroom to the living room through ‘Xplorations’, a teaching ministry emphasising Hebraic roots, which he co-founded and directs with his wife Heather. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      